import React from "react";
import Layout from '../components/layout';
import { graphql } from "gatsby";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const aboutPage = ({ data }) => (
	<Layout>

		<Row>
			<Col>
				<h1>Find Us Here</h1>
				<ListGroup variant="flush" >
					<ListGroup.Item style={{background:'transparent'}}><h2>Head Office & Manufacturing Unit</h2>
						<h3>Company Information</h3>

						<p>Address: {data.site.siteMetadata.headOfficeAddress}</p>
						<p>Phone: {data.site.siteMetadata.headOfficePhone}</p>
						<p>Email: <a href={'mailto:' + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a></p></ListGroup.Item>
					<ListGroup.Item style={{background:'transparent'}}><h2>Coachin Office & Sales Depot</h2>
						<h3>Company Information</h3>
						<p>Address: {data.site.siteMetadata.CoachinOffice}</p>
						<p>Phone: {data.site.siteMetadata.CoachinOfficePhone}</p></ListGroup.Item>
					<ListGroup.Item style={{background:'transparent'}}><h2>Calicut Office and Sales Depot</h2>
						<h3>Company Information</h3>
						<p>Address: {data.site.siteMetadata.CalicutOffice}</p>
						<p>Phone: {data.site.siteMetadata.CalicutPhone}</p></ListGroup.Item>

				</ListGroup>

			</Col>
		</Row>
		<Col>
			<Row>
				<Col>
					<h1>Contact us</h1>

				</Col>
			</Row>
			<Row>
				<Col sm={6} xs={12}>
					<Form method="POST" data-netlify="true" name="Contact Us">
						<input type="hidden" name="form-name" value="Contact Us" />
						<Form.Group controlId="formBasicName">
							<Form.Label>Name</Form.Label>
							<Form.Control type="text" name="name" />

						</Form.Group>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Email address</Form.Label>
							<Form.Control type="email" name="email" />
							<Form.Text className="text-muted">
								We'll never share your email with anyone else.
							</Form.Text>
						</Form.Group>

						<Form.Group controlId="formBasicPhone">
							<Form.Label>Phone</Form.Label>
							<Form.Control type="number" name="number" />
						</Form.Group>
						<Form.Group controlId="formBasicTextarea">
							<Form.Label>Message</Form.Label>
							<Form.Control as="textarea" rows="3" name="message"/>
						</Form.Group>
						<Button className="bootstrapButton" type="submit">
							Submit
				</Button>
					</Form>
				</Col>
			</Row>

			{/* <form method="POST" data-netlify="true" name="contact">
			<input type="hidden" name="form-name" value="contact" />
			<label >Name: </label>
			<input type="text" name="name" placeholder="Enter Your Name"></input>
			<br />
			<label >Email: </label>
			<input type="text" name="email" placeholder="john@example.com"></input>
			<br />
			<label >Contact Number: </label>
			<input type="number" name="phone" placeholder="+91"></input>
			<br />
			<label>Message: </label>
			<input type="text" name="message"></input>
			<br />
			<button type="submit">Submit</button>
		</form> */}
		</Col>

	</Layout>
)

export default aboutPage;
export const query = graphql
	`
query {
	site {
	  id
	 siteMetadata {
	   title
	   description
	   author
	   headOfficeAddress
	   headOfficePhone
	   CoachinOffice
	   CoachinOfficePhone
	   CalicutOffice
	   CalicutPhone
	   email
	 }
	}
  }
`